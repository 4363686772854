
import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import WidgetEmbed from '@/components/widgets/WidgetEmbed.vue';
import RoleGuard from '@/utility/auth/RoleGuard.vue';
import { FeatureToggleName } from '@govflanders/mbp-admin-panel-shared';
import { mixins } from 'vue-class-component';

@Component({
  components: {
    WidgetEmbed,
  },
})
export default class DefaultPage extends mixins(RoleGuard) {
  @Getter('app/isAuthenticated')
  private isAuthenticated!: boolean;

  @Getter('featureToggles/isFeatureAvailable')
  public isFeatureAvailable: (name: FeatureToggleName) => boolean;

  public get contactsEnabled() {
    return (
      this.isFeatureAvailable && this.isFeatureAvailable(FeatureToggleName.CONTACT_OPTIONS_ENABLED)
    );
  }
  public get certificatesEnabled() {
    return (
      this.isFeatureAvailable && this.isFeatureAvailable(FeatureToggleName.CERTIFICATES_ENABLED)
    );
  }
  public get ipdcProductsEnabled() {
    return (
      this.isFeatureAvailable && this.isFeatureAvailable(FeatureToggleName.IPDC_PRODUCTS_ENABLED)
    );
  }
  public get ipdcThemesEnabled() {
    return (
      this.isFeatureAvailable && this.isFeatureAvailable(FeatureToggleName.IPDC_THEMES_ENABLED)
    );
  }
}
